import { useEffect, useState } from 'react';
import useCustomMediaQuery from 'src/lib/hooks/useCustomMediaQuery';

const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState('');

  const xlBreakpoint = useCustomMediaQuery('xl');
  const lgBreakpoint = useCustomMediaQuery('lg');
  const mdBreakpoint = useCustomMediaQuery('md');
  const smBreakpoint = useCustomMediaQuery(undefined, 'sm');

  useEffect(() => {
    switch (true) {
      case xlBreakpoint:
        setBreakpoint('xl');
        break;
      case lgBreakpoint:
        setBreakpoint('lg');
        break;
      case mdBreakpoint:
        setBreakpoint('md');
        break;
      case smBreakpoint:
        setBreakpoint('sm');
        break;
      default:
        setBreakpoint(undefined);
    }
  }, [xlBreakpoint, lgBreakpoint, mdBreakpoint, smBreakpoint]);

  return breakpoint;
};

export default useBreakpoint;
