import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useBreakpoint from 'src/lib/hooks/useBreakpoint';

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [breakpoint, setBreakpoint] = useState('');

  const currentBreakpoint = useBreakpoint();

  useEffect(() => {
    setBreakpoint(currentBreakpoint);
  }, [currentBreakpoint]);

  return (
    <AppContext.Provider value={{ breakpoint }}>{children}</AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useAppContext = () => {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppContext must be used within a AppProvider');
  }
  return context;
};

export { AppProvider, useAppContext };
